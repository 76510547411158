var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],attrs:{"title":_vm.tooltip}},[_c('div',{staticClass:"toggle",class:{
      'toggle-off': !_vm.on,
      'toggle-on': _vm.on,
      'toggle-disabled': _vm.disabled,
      'toggle-loading': _vm.loading
    },attrs:{"disabled":_vm.disabled},on:{"click":_vm.toggle}},[_c('div',{staticClass:"toggle-switch justify-items-center",class:{
        'toggle-switch-off': !_vm.on,
        'toggle-switch-on': _vm.on
      }})])])}
var staticRenderFns = []

export { render, staticRenderFns }