import { render, staticRenderFns } from "./ConnectionModal.vue?vue&type=template&id=53929e02&v-slot%3Amodal-header=%7B%20close%20%7D&title=Connect%20to%20Bitcoin%20Node&"
import script from "./ConnectionModal.vue?vue&type=script&lang=js&"
export * from "./ConnectionModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports