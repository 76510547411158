var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"pt-2 pb-3"},[_c('div',{staticClass:"mb-1"},[(_vm.numberValue === -1)?_c('span',{staticClass:"loading-placeholder loading-placeholder-lg w-50 mt-2"}):_c('div',{staticClass:"d-flex align-items-baseline"},[_c('h3',{staticClass:"font-weight-normal mb-0"},[_c('CountUp',{attrs:{"value":{
              endVal: _vm.numberValue,
              decimalPlaces: _vm.hasDecimals ? 5 : 0
            },"suffix":_vm.numberSuffix,"countOnLoad":""}})],1),_c('span',{staticClass:"text-muted",staticStyle:{"margin-left":"0.5rem"}},[_vm._v(_vm._s(_vm.suffix))]),_c('div',{staticClass:"ml-1"},[(_vm.showPopover)?_c('b-popover',{attrs:{"target":_vm.popoverId,"placement":"bottom","triggers":"hover focus"}},_vm._l((_vm.popoverContent),function(content){return _c('p',{key:content,staticClass:"m-0"},[_vm._v(_vm._s(content))])}),0):_vm._e(),(_vm.showPopover)?_c('b-icon',{staticClass:"text-muted",staticStyle:{"cursor":"pointer"},attrs:{"id":_vm.popoverId,"icon":"info-circle","size":"lg"}}):_vm._e()],1)])]),((_vm.showNumericChange || _vm.showPercentChange) && _vm.change.value !== 0)?_c('div',[_c('svg',{staticClass:"change-arrow",class:{
          rising: _vm.change.value > 0,
          declining: _vm.change.value < 0,
          neutral: _vm.change.value === 0
        },attrs:{"width":"12","height":"13","viewBox":"0 0 12 13","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M1.11092 1.90381C1.11092 2.45609 1.55863 2.90381 2.11092 2.90381H8.18198L0.696702 10.3891C0.306178 10.7796 0.306178 11.4128 0.696702 11.8033C1.08723 12.1938 1.72039 12.1938 2.11092 11.8033L9.69588 4.21833L9.95069 10.079C9.97468 10.6307 10.4414 11.0586 10.9932 11.0346C11.545 11.0106 11.9728 10.5439 11.9488 9.9921L11.5953 1.86037C11.572 1.32549 11.1316 0.903809 10.5962 0.903809H2.11092C1.55863 0.903809 1.11092 1.35152 1.11092 1.90381Z","fill":"#00CD98"}})]),_c('span',{staticClass:"change-text ml-1",class:{
          'text-success': _vm.change.value > 0,
          'text-danger': _vm.change.value < 0,
          'text-muted': _vm.change.value === 0
        }},[_vm._v(" "+_vm._s(_vm.change.value >= 0 ? "+" : "")+_vm._s(_vm.change.value)+_vm._s(_vm.change.suffix)+" ")])]):_c('div',{staticClass:"d-block"},[_c('span',{staticStyle:{"opacity":"0"}},[_vm._v(".")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }