<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center min-vh100 p-2"
  >
    <img alt="Umbrel" src="@/assets/icon.svg" class="mb-5 logo" />

    <!-- <b-spinner class="my-4" variant="primary"></b-spinner> -->
    <small v-if="isRestarting" class="text-muted mb-3">Restarting...</small>
    <b-progress
      :value="progress"
      class="mb-2 w-75"
      variant="orange"
      :style="{ height: '4px', maxWidth: '360px' }"
    ></b-progress>
    <slot></slot>
    <div class="loading-footer text-center d-flex flex-column align-items-center py-4">
      <!-- <span class="loading-footer-text-top text-muted">By</span> -->
      <svg class="loading-footer-logo mt-2 mb-1" width="158" height="78" viewBox="0 0 158 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1988 75.7495C12.4311 75.5324 12.6498 75.2995 12.8529 75.052C16.0151 71.379 20.9667 69.0475 29.0071 69.0475C36.586 69.0475 43.0874 71.1536 48.7727 75.3155L48.9971 75.4826C52.0862 77.7822 56.2918 77.8771 59.4814 75.719C66.275 71.1226 72.8576 69.0475 79.3404 69.0475C85.7084 69.0475 91.8875 71.0474 98.0126 75.3959L98.1404 75.4873C101.54 77.9196 106.163 77.7088 109.328 74.9771C113.374 71.4849 118.91 69.447 126.613 69.447C134.72 69.447 141.005 71.7095 145.968 75.901C146.178 76.0852 146.398 76.2568 146.627 76.4152C147.341 76.9116 148.121 77.2629 148.926 77.4722C149.973 77.7458 151.094 77.7881 152.216 77.5557C153.038 77.3869 153.833 77.0754 154.563 76.6246C155.32 76.1571 155.974 75.5588 156.503 74.8687C157.194 73.9684 157.643 72.9513 157.855 71.9004C158.022 71.0808 158.049 70.2221 157.915 69.358C157.872 69.0779 157.812 68.8002 157.737 68.5262C151.988 46.258 142.591 28.8229 129.015 17.0478C115.293 5.14669 97.9968 -0.369814 77.7503 0.0192022C57.5849 0.406661 40.5372 6.30414 27.2358 18.2035C14.0492 30.0002 5.21903 47.0914 0.218671 68.6939C0.149504 68.9702 0.0969723 69.2494 0.0609487 69.5301C-0.215382 71.6466 0.462719 73.7075 1.79439 75.2316C2.45332 75.9857 3.27228 76.6085 4.21481 77.0415C4.89471 77.3552 5.61045 77.5566 6.33552 77.6479C8.53014 77.9288 10.6634 77.1841 12.1988 75.7495ZM78.0287 14.5071C95.332 14.1746 108.954 18.8301 119.521 27.9945C127.197 34.6523 133.616 43.9998 138.58 56.3453C134.798 55.4133 130.8 54.9564 126.613 54.9564C117.758 54.9564 109.811 57.0049 103.19 61.4625C95.7727 56.9361 87.7928 54.5569 79.3404 54.5569C70.6956 54.5569 62.3971 57.044 54.5211 61.7126C46.919 56.9304 38.3531 54.5569 29.0071 54.5569C25.6322 54.5569 22.403 54.8701 19.3567 55.5249C23.8208 44.1707 29.7267 35.4179 36.8972 29.0032C47.1433 19.8371 60.6444 14.8411 78.0287 14.5071Z" fill="#5351FB" />
      </svg>
      <span class="oading-footer-text-bottom text-primary font-weight-bold">Umbrel</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRestarting: false
    };
  },
  props: { progress: Number },
  created() {
    if (this.$route.query.hasOwnProperty("restart")) {
      this.isRestarting = true;
      this.$router.replace({ query: {} });
    }
  },
  methods: {},
  components: {}
};
</script>

<style lang="scss">
.logo {
  height: 18vh;
  max-height: 200px;
  width: auto;
  border-radius: 22%;
}
.loading-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  .loading-footer-logo {
    height: 30px;
  }
}
</style>
